import { useGlobalContext } from "../context/AppContext";
import MarketingStrategy from "./marketingstrategy";
import Posts from "./posts";

export default function Home() {
  const { currentPage } = useGlobalContext();
  //console.log("Current Page: " + currentPage);

  return currentPage == "posts" ? <Posts /> : <MarketingStrategy />;
}
